import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following table lists the metrics available in all of Contact Center and Analytics Studio aggregation and metrics panels, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Source`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category > Sub-category`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique Actions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classified Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for AI Classified Contacts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Accepted:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Onsite Campaigns`}</li><li>{`Outbound Campaigns Actions`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Actions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Added`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Segments Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Added:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts Attempts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Attempts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`-`}{` `}{`Daily`}{` `}{`average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Onsite Campaigns`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Attributed conversion value:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Available:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available operators`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Available operators:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`Operators available to handle interactions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Bot Name:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Bounces:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts Sales`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Bundles:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Busy:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Callbacks:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Callbacks canceled:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkout items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Checkout items:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkout value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Checkout value:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkouts influenced by contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Checkouts influenced by contacts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkouts influenced by impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Checkouts influenced by impressions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Classified contacts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Chatbot`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Click2Call:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Click2Call requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Chatbot`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Click2Chat:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Chat requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Click2Chat requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Onsite Campaigns`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contact requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`-`}{` `}{`Daily`}{` `}{`average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`DAvg`}{` `}{`-`}{` `}{`Daily`}{` `}{`average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Email`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts - Email:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Push Notification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts - Push Notification:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns SMS`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts - SMS:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts - Voice:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts with cobrowse:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts Sales`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacts with sales:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Conversions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Conversion value:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Conversions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Conversions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li><li>{`Outbound Campaigns Email`}</li><li>{`Outbound Campaigns Push Actions`}</li><li>{`Outbound Campaigns SMS`}</li><li>{`Outbound Campaigns Voice`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Credits:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DTMF Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVR Nodes`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for DTMF Success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Duration:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Effective Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Effective Busy:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Effective Busy Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Effective Busy Time:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Events`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Events:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Executions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Executions:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback (Maximum executions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Fallback (Maximum executions):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback (Time limit)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Fallback (Time limit):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Forwardings:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Has transcript?:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Drop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVR Nodes`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for IVR Drop:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for IVR duration (s):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Onsite Campaigns`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Influenced conversions:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Instances:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Canned Messages`}</li><li>{`Chatbot`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Interactions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Last node:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Leads:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Logged In Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Logged In Time:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node duration (ms)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Node duration (ms):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Chatbot Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Node interactions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node session expirations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Chatbot Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Node session expirations:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping cart items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Nr. of shopping cart items:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping carts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Nr. of shopping carts:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nr. of shopping carts with checkout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Nr. of shopping carts with checkout:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ongoing Calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Ongoing Calls:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Page requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Pause:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Pending Callbacks:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Price of RGU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts Sales`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Price of RGU:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Sales`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for RGUs:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Refused`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Refused:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Removed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Segments Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Removed:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Sales:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Schedule`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Chatbot`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Schedule:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scheduled calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Scheduled calls:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Session duration:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Shopping carts value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`ECommerce`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Shopping carts value:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Short calls:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Available:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Busy:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Pause:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Total contacts:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique visitors:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (accepted)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique visitors (accepted):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with influenced conversions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique visitors (with influenced conversions):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors with impressions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique visitors with impressions):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of DTMF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Usage of DTMF:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR Nodes`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Usage of voice:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Visits:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVR Nodes`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Voice Recognition Accuracy:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Wait time:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Onsite Campaigns`}</li><li>{`Outbound Campaigns Actions`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Clicks:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Broadcast`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entries`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Entries:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Broadcast`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Onsite Campaigns`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Broadcast`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Browser Views:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser Views`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Browser Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique Browser Views:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser Views`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Duration (Click2Chat):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Live Chat:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li><li>{`IVR Nodes`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Nodes used:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Number of interactions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success (Click2Chat):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Chatbot Stats`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time on node:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Transfer Requests:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Transfers with Success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Transfers without Success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess (Click2Chat):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Clicks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique Clicks:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action executed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns SMS`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Action executed:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert (soft bounce)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Email`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Alert (soft bounce):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Awaiting confirmation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Outbound Campaigns Push Actions`}</li><li>{`Outbound Campaigns SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Awaiting confirmation:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Clicked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Clicked:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Closed:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacted:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contacting:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delivered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Delivered:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Failed:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finished with success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Finished with success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finished without success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Finished without success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ignored (blacklist)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Email`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Ignored (blacklist):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ignored due to capping`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Outbound Campaigns Email`}</li><li>{`Outbound Campaigns SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Ignored due to capping:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`In contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for In contact:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Read`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Email`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Read:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rescheduled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Rescheduled:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Returned (hard bounce)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Email`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Returned (hard bounce):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent with success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Outbound Campaigns Email`}</li><li>{`Outbound Campaigns SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Sent with success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent without success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Outbound Campaigns Email`}</li><li>{`Outbound Campaigns SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Sent without success:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`To be sent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Push Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for To be sent:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`To contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for To contact:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transferred to skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Voice`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Transferred to skill:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsubscribed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Outbound Campaigns Email`}</li><li>{`Outbound Campaigns SMS`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsubscribed:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Content`}</li><li>{`Onsite Campaigns`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Impressions:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Impressions (baseline):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Impressions (with A/B variants):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Impressions (with profiles):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Impressions (with segments):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique sessions (with prints):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique visitors (with prints):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Detail Type (experience):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Detail Type (segmentation):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique contents:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique elements:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content Feedback > Content Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Form:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Available - Inbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Busy - Inbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Available - Inbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy - Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Busy - Inbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Time Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Total Time Inbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns SMS`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Error message:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Others`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Available - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Available - Outbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Busy - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Busy - Outbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Available - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Available - Outbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time Busy - Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time Busy - Outbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Time Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contact Center Activity`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Total Time Outbound:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Closed leads:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Closed leads with sale:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Closed leads without sale:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Open Leads:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Beginning of the call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Beginning of the call:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End of the call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for End of the call:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mid-call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for mid-call:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success (VideoCall):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess (VideoCall):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for VideoCall:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unique Views:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Views`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Outbound Campaigns Actions`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Views:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Views`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Abandonment on queue (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Abandonment on queue (Inbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Abandonment on queue (Outbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Call waiting time:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Call-center did not answer (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Classified contacts (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Client did not answer (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Client did not answer (Outbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contact center did not answer (Inbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Contact center did not answer (Outbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Credits (Click2Call):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Credits (Inbound):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Credits (Outbound):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Duration (Click2Call):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Duration (Inbound):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Duration (Outbound):`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Human validation:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Identified contacts (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Inbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Invalid - Client (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Invalid - Contact Center (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Outbound:`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Short calls (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts Attempts`}</li><li>{`Contacts`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Short calls (Inbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success (Inbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success (Outbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Success classified (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Time until call:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Total call duration:`}<ul><li>{`∑`}{` `}{`-`}{` `}{`Sum`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li><li>{`.95`}{` `}{`-`}{` `}{`95th`}{` `}{`percentile`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess (Inbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess (Outbound):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess - Silence (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Unsuccess - Voicemail (Click2Call):`}<ul><li>{`#`}{` `}{`-`}{` `}{`Count`}</li><li>{`%`}{` `}{`-`}{` `}{`Percentage`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T0:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T1:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T2:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T3:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T4:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T5:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T6:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T7:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T8:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T9:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Calls - T9+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Calls - T9+:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T0:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T1:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T2:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T3:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T4:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T5:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T6:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T7:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T8:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T9:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time - T9+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Voice Stats`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{/*symbols:star*/}{`Metrics for Waiting time - T9+:`}<ul><li>{`µ`}{` `}{`-`}{` `}{`Average`}</li><li>{`m`}{` `}{`-`}{` `}{`Minimum`}</li><li>{`M`}{` `}{`-`}{` `}{`Maximum`}</li></ul>{/*symbols:end*/}{`TBD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Stats > mk_tnum`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      